import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Role} from "../model/PokerPlayer";
import {Button, createStyles, FormControl, Grid, Input, InputLabel, makeStyles} from "@material-ui/core";
import {FormattedMessage, IntlProvider} from "react-intl";
import ControllerContext from "../contexts/ControllerContext";
import EnableLoadingStateContext from "../contexts/EnableLoadingStateContext";
import {getCurrentUser, User, withLogin, WithLoginProps} from "@2gether/frontend-library";
import PlayerNameInputComponent from "../components/Session/PlayerNameInputComponent";
import RoleSelectionComponent from "../components/Session/RoleSelectionComponent";
import logger from "../util/Logger";
import {AuthorizationContext} from "../contexts/AuthorizationContext";
import {AccessDeniedPage} from "./AccessDeniedPage";
import deLocale from "../locales/de.json";
import enLocale from "../locales/en.json";

const SESSION_NAME_MAX_LENGTH = 35;
const ROLE_VOTER: string = "Voter"
const ROLE_OBSERVER: string = "Observer"
const PLAYER_NAME_DISPLAY_MAX_LENGTH = 62


const useStyles = makeStyles((theme) =>
    createStyles({
        form: {
            width: "340px",
            boxShadow: "0 0 3px 0 rgba(0,0,0,0.3)",
            background: "#ffffff",
            padding: "20px",
            textAlign: "center",
            borderRadius: "3px",
            marginTop: 15
        },
        pokerTitle: {color: "#282c34", margin: "0 0 15px 0"},
        textField: {width: "280px"},
        formButtons: {
            margin: "5px !important",
            backgroundColor: theme.palette.login.main,
            "&:hover": {backgroundColor: theme.palette.login.main},
            "&.Mui-disabled": {
                color: theme.palette.buttonText.main,
                backgroundColor: "gray"
            },
        },
    })
);

export const CreateSessionPage: React.FC<WithLoginProps> = (props: WithLoginProps) => {
    const {mainController} = useContext(ControllerContext)
    const enableLoadingState = useContext(EnableLoadingStateContext)

    const styles = useStyles();

    const [tableName, setTableName] = useState("");
    const [playerName, setPlayerName] = useState("");
    const [cognitoUser, setCognitoUser] = useState<User | undefined>(undefined)
    const [playerRole, setPlayerRole] = useState(Role.VOTER);
    const {userAuthorized, updateUser} = useContext(AuthorizationContext)

    const [locale, setLocale] = useState(mainController.getLocaleSetting());
    const messages : any = {
        de: deLocale,
        en: enLocale
    }

    useEffect(updateUser, [updateUser])

    useEffect(() => {
        getCurrentUser()
            .then((user) => {
                const name = user.givenName + ' ' + user.familyName
                setPlayerName(name.substring(0, PLAYER_NAME_DISPLAY_MAX_LENGTH - 1))
                setCognitoUser(user)
            })
            .catch(() => props.signOut())
    }, [])

    const createPlayer = async (cognitoID: string | undefined, tableID: string) => {
        let playerProfileFromDB = undefined;
        if (!!cognitoID) {
            playerProfileFromDB = await mainController.getPlayerProfileFromDB(cognitoID)
        }
        mainController.createPlayer(playerName, cognitoID, playerRole, tableID, !userAuthorized, playerProfileFromDB)
    }

    const createButtonClicked = useCallback(
        async (): Promise<void> => {
            enableLoadingState();
            const tableID = await mainController.createTable(tableName)
            const cognitoID = cognitoUser ? cognitoUser.username : undefined
            await createPlayer(cognitoID, tableID)
            await mainController.joinTable(tableID).catch(reason => {
                logger.error(reason.message)
                mainController.checkLogin()
            })
        },
        [enableLoadingState, mainController, tableName, playerName, cognitoUser, playerRole, userAuthorized]
    )

    const updateSessionName = useCallback(
        (event) => {
            setTableName(event.target.value);
        },
        [setTableName]
    )
    useEffect(() => {
        localStorage.removeItem('player');
    }, [])


    const updatePlayerName = useCallback(
        (event) => {
            const name = event.target.value
            if (name.length < PLAYER_NAME_DISPLAY_MAX_LENGTH)
                setPlayerName(name)
        },
        [setPlayerName]
    )

    const updatePlayerRole = useCallback(
        (event) => {
            const roleString = event.target.value;
            let role: Role;
            switch (roleString) {
                case ROLE_VOTER:
                    role = Role.VOTER;
                    break;
                case ROLE_OBSERVER:
                    role = Role.OBSERVER;
                    break;
                default:
                    return;
            }
            setPlayerRole(role);
        },
        [setPlayerRole]
    )

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        createButtonClicked().then().catch(reason => logger.error(reason.message))
    }, [createButtonClicked])

    const sessionInputElement = (
        <FormControl className={styles.textField}>
            <InputLabel
                htmlFor="session-name"
                color={"primary"}>
                <b><FormattedMessage id={"login_page-text-session_name"}/></b>
            </InputLabel>
            <Input
                value={tableName}
                onChange={updateSessionName}
                type={"text"}
                name={"session-name"}
                color={"primary"}
                inputProps={
                    {
                        maxLength: SESSION_NAME_MAX_LENGTH
                    }
                }/>
        </FormControl>
    )

    const nameInputElement = (
        <PlayerNameInputComponent
            nameValue={playerName} onChange={updatePlayerName}/>
    )

    const roleSelectionElement = (
        <RoleSelectionComponent onChange={updatePlayerRole} options={[ROLE_VOTER, ROLE_OBSERVER]}/>
    )


    const createButtonEnabled = playerName.trim().length !== 0 && tableName.trim().length !== 0;
    const createButtonElement = (

        <Button
            className={styles.formButtons}
            type={"submit"}
            color={"primary"}
            variant={"contained"}
            disabled={!createButtonEnabled}>
            <FormattedMessage id={"login_page-text-create_session"}/>
        </Button>
    )

    if (userAuthorized) {
        return (
            <IntlProvider
                locale={locale}
                key={locale}
                messages={messages[locale]}>
                <Grid container justifyContent="center">
                    <form className={styles.form} onSubmit={onSubmit}>
                        <h3 className={styles.pokerTitle}>Poker2Gether</h3>
                        {sessionInputElement}
                        {nameInputElement}
                        {roleSelectionElement}
                        {createButtonElement}
                    </form>
                </Grid>
            </IntlProvider>
        )
    } else {
        return <AccessDeniedPage/>
    }
}
const cookieStorage = {
    domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN!,
    secure: false
}

function getExternalProviders() {
    if (process.env.REACT_APP_EXTERNALADFS_LIST === undefined) {
        return [];
    }

    const providers = process.env.REACT_APP_EXTERNALADFS_LIST
        .split(",")
        .filter(o => o.trim().length > 0)
        .map(o => {
            const x = o.split("/")
            return {
                name: x[0],
                id: x[1],
                hidden: process.env.REACT_APP_EXTERNALADFS_HIDDEN?.trim() === "true" ?? false
            }
        });

    return providers;
}

export default withLogin({
    adminsOnly: false,
    requireAcl: false,
    cognitoUrl: "https://" + process.env.REACT_APP_OAUTH_DOMAIN!,
    clientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID!,
    redirectUri: process.env.REACT_APP_PUBLIC_URI!,
    adfsIdentityProvider: process.env.REACT_APP_IDENTITY_PROVIDER!,
    externalAdfsProviders: getExternalProviders(),
    cookieStorage,
})(CreateSessionPage)


