
import React, {createContext, useCallback, useContext, useEffect, useState} from 'react'
import {Auth,Hub} from "aws-amplify";
import {getCurrentUser} from "@2gether/frontend-library";



export interface UserContextValue {
    userAuthorized: boolean
    userLoggedIn: boolean
    updateUser(): void
    logout():void
}

export const AuthorizationContext = createContext<UserContextValue>(
    {userAuthorized: false,
        userLoggedIn: false,
        updateUser: () => {},
        logout:()=>{}
    }
    )



const UserProvider : React.FC = ({ children }) => {

    const [userAuthorized, setUserAuthorized] = useState<boolean>(false)
    const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false)

    const updateUser = useCallback(() => {
        Auth.currentAuthenticatedUser().then(u => console.log("curr ",u))
        Auth.currentSession()
            .then(() => getCurrentUser())
            .then(user => {
                    setUserAuthorized(user.isAuthorized)
                    setUserLoggedIn(true)
            })
            .catch(() => {
                setUserLoggedIn(false)
            })
    }, [setUserAuthorized, setUserLoggedIn])

    useEffect(() => {
        updateUser()
    }, [updateUser])


    const logout = ()=>{
        Auth.currentSession().then(u => console.log(u))
        Auth.signOut({global:true}).then(_ =>{
            setUserAuthorized(false)
            setUserLoggedIn(false)
            const origin = window.location.origin
            window.location.href = origin
        }).catch(e => console.log("error while logging out"))

    }

    const contextValue = {userAuthorized: userAuthorized, userLoggedIn: userLoggedIn, updateUser: updateUser,logout}
    return <>
        <AuthorizationContext.Provider value={contextValue}>{children}</AuthorizationContext.Provider>
    </>
}

export default UserProvider