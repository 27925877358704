import React, {useContext, useMemo} from "react";
import PokerTable, {TableState} from "../model/PokerTable";
import logo from "../images/logo.png"
import {Grid, Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {asCssRGBAString, PokerTheme} from "../model/PokerTheme";
import {FormattedMessage, useIntl} from "react-intl";
import PokerPlayer from "../model/PokerPlayer";
import logger from "../util/Logger";
import ControllerContext from "../contexts/ControllerContext";
import  {AuthorizationContext} from "../contexts/AuthorizationContext";

interface Props {
    table?: PokerTable
    pokerTheme: PokerTheme
    selfPlayer?: PokerPlayer
}

const useStyles = makeStyles(() => ({
    header: (pTheme: PokerTheme) => ({
        flexShrink: 0,
        //overflow: "hidden",
        textAlign: "center",
        backgroundColor: asCssRGBAString(pTheme.general.header.background),
        color: asCssRGBAString(pTheme.general.header.text),
        padding: "10px",
        fontFamily: "sans-serif",
    }),
    headerImage: {
        "@media only screen and (min-width: 670px) and (max-width: 1023px)": {
            maxWidth: "640px",
            maxHeight: "118px"
        },
        "@media only screen and (min-width: 200px) and (max-width: 670px)": {
            maxWidth: "320px",
            maxHeight: "56px"
        }
    },
    sessionName: {
        fontStyle: "normal",
        fontWeight: "bold",
    },
    subline: {
        display: "inline-block",
        fontStyle: "italic"
    },
    info: {
        display: "inline-block",
    },
    state: {
        display: "inline-block",
        fontWeight: "bold"
    },
    observerAsAdmin: (pTheme: PokerTheme) => ({
        maxWidth: "100%",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        marginBottom: "5px",
        "&:hover": {
            color: asCssRGBAString(pTheme.general.header.text)
        }
    }),
    observer: (pTheme: PokerTheme) => ({
        maxWidth: "100%",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        marginBottom: "5px",
        "&:hover": {
            color: asCssRGBAString(pTheme.panel.text)
        }
    }),
    observerListAsAdmin: (pTheme: PokerTheme) => ({
        visibility: "hidden",
        backgroundColor: asCssRGBAString(pTheme.panel.background),
        padding: "0.5em",
        position: "absolute",
        zIndex: 10,
        left: "-0.5em",
        textAlign: "left",
        width: "fit-content",
        maxWidth: "35vw",
        cursor: "pointer"
    }),
    observerList: (pTheme: PokerTheme) => ({
        visibility: "hidden",
        backgroundColor: asCssRGBAString(pTheme.panel.background),
        padding: "0.5em",
        position: "absolute",
        zIndex: 10,
        left: "-0.5em",
        textAlign: "left",
        width: "fit-content",
        maxWidth: "35vw",
        cursor: "default"
    }),
    observerBlockPosition: {
        paddingTop: "0px",
        textAlign: "center",
        cursor: "default",
        "&:hover": {
            "& $observerList": {
                visibility: "visible"
            },
            "& $observerListAsAdmin": {
                visibility: "visible"
            }
        }
    },
    logout:{
      cursor:"pointer"
    }
}))

export const PageHeaderComponent: React.FC<Props> = (props) => {
    const {table, pokerTheme, selfPlayer} = props
    const {mainController} = useContext(ControllerContext)
    const {logout,userAuthorized,userLoggedIn} = useContext(AuthorizationContext)
    const classes = useStyles(pokerTheme)
    const intl = useIntl();

    const onClickSwitchPlayerRoleByAdmin = (player: PokerPlayer) => {
        if (selfPlayer != undefined && table != undefined) {
            if (selfPlayer.canControlTable(table)) {
                mainController.switchPlayerRoleByAdmin(table.id, player).catch(err => logger.error(err));
            }
        }
    }

    const getChangeMind = useMemo(() => {
            if (table === undefined)
                return null
            const enabled = table.isChangeOpenCardEnabled()
            let toText;
            if (enabled)
                toText = <FormattedMessage id={"page_header_component-text-change_allowed"}/>
            else
                toText = <FormattedMessage id={"page_header_component-text-change_not_allowed"}/>
            return <p className={classes.info}>
                {toText}
            </p>
        },
        [table, classes.info]
    )
    const renderLogout = ()=>{
        return(
            <span onClick={logout}>
                <FormattedMessage id={"page_header_component-text-logout"}/>
            </span>
        )
    }

    const renderTableInfos = useMemo(
        () => {
            if (table === null || table === undefined) {
                return (<div/>)
            }
            const nameText = table!!.name;
            const hostText = `${table!!.admin !== undefined ? table!!.admin.name :
                <FormattedMessage id={"page_header_component-text-admin_away"}/>}`;
            const VotingTitle = table.votingTitle ? table.votingTitle : "";
            const stateText = table!!.state === TableState.VOTING_ENABLED ?
                <FormattedMessage id={"page_header_component-text-state_active"}/>
                : <FormattedMessage id={"page_header_component-text-state_inactive"}/>;
            const stateColor = table!!.isVotingEnabled() ?
                {color: asCssRGBAString(pokerTheme.general.header.status.approve)} :
                {color: asCssRGBAString(pokerTheme.general.header.status.reject)};

            const votingTitleText = (VotingTitle === "") ? "" : `${intl.formatMessage({id: "for"})} ${VotingTitle}`
            return (
                <div className={classes.header}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={2} md={2}>
                            <p className={classes.subline}>
                                <FormattedMessage id={"page_header_component-text-table-by"}
                                                  values={{
                                                      table: <span className={classes.sessionName}>{nameText}</span>,
                                                      user: hostText
                                                  }}/>
                            </p>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            {table.isVotingEnabled() && table.cardsRevealed &&
                            <>{getChangeMind}</>
                            }
                        </Grid>
                        <Grid item xs={2} md={2} className={classes.observerBlockPosition} data-testid="observerBlock">
                                <span
                                    style={{position: "relative"}}>{intl.formatMessage({id: "login_page-text-role_observer"})}: {table.observerPlayers.length}
                                    {table.observerPlayers.length > 0 &&
                                    <Paper
                                        data-testid="observerList"
                                        className={selfPlayer!!.canControlTable(table)
                                            ? classes.observerListAsAdmin
                                            : classes.observerList}>
                                        {
                                            table.observerPlayers.map(({name}, id) => (
                                                <p
                                                    key={id}
                                                    onClick={() => onClickSwitchPlayerRoleByAdmin(table.observerPlayers[id])}
                                                    className={selfPlayer!!.canControlTable(table)
                                                        ? classes.observerAsAdmin
                                                        : classes.observer}>
                                                    {name}
                                                </p>
                                            ))
                                        }
                                    </Paper>
                                    }
                                </span>
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <p className={classes.state} style={stateColor}>
                                {stateText} {votingTitleText}
                            </p>
                        </Grid>
                       <Grid className={classes.logout} item xs={2}>
                           {userLoggedIn && renderLogout()}
                       </Grid>
                    </Grid>
                </div>
            );
        },
        [table, classes.header, classes.sessionName, classes.state, classes.subline, getChangeMind, pokerTheme]
    )

    return (useMemo(
        () => {
            const welcomeHeader = (
                <div className={classes.header}>
                    <img className={classes.headerImage} src={logo}
                         alt={intl.formatMessage({id: "page_header_component-text-header_alt"})}/>
                </div>
            )
            if (table == null) {
                return <>{welcomeHeader}</>
            } else {
                return <>{renderTableInfos}</>
            }
        },
        [table, renderTableInfos, classes.header, classes.headerImage, intl]
    ))
}
